<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-overlay :show="loading">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <!-- <img src="cybercorp.png" alt="" width="40%" height="40%" /> -->
            <!-- <img src="spacar.png" alt="" width="60%" height="60%" /> -->
            <!-- <img src="sifacturo.svg" alt="" width="70%" height="70%" /> -->
            <img :src="logo" alt="" width="40%" height="40%" />
            <!-- <vuexy-logo /> -->

            <!-- <h2 class="brand-text text-primary ml-1">SIFACTURO POS</h2> -->
          </b-link>

          <b-card-title class="mb-1">
            Benvenido a {{ appName }}! 👋</b-card-title
          >
          <b-card-text class="mb-2"> Inicie sesion con su cuenta </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
                class="mb-0-5"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.correo"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="juanperez@ejemplo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="mb-0-5">
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{name:'auth-forgot-password-v2'}">
                      <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Recuérdame
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                class="my-1"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Acceder
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'auth-register-v1'}">
                <span>Create an account</span>
              </b-link>
            </b-card-text> -->

          <!-- <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div> -->

          <!-- social button -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                href="javascript:void(0)"
                variant="facebook"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                href="javascript:void(0)"
                variant="twitter"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                href="javascript:void(0)"
                variant="google"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                href="javascript:void(0)"
                variant="github"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
        </b-card>
        <!-- /Login v1 -->
      </b-overlay>
    </div>

    <b-modal
      id="modal-1"
      v-model="modal"
      title="Seleccione una sucursal"
      ok-only
      no-close-on-esc
      no-close-on-backdrop
      ok-title="Aceptar"
      button-size="sm"
      :hide-header="false"
      @ok.prevent="ingresar"
      :ok-disabled="!sucursal_id"
    >
      <template #modal-header="{}">
        <h5>Seleccione una Sucursal</h5>
      </template>
      <div>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group label="* SUCURSAL" label-for="sucursal">
              <b-form-select
                v-model="sucursal_id"
                :options="sucursalItems"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  BCard,
  BModal,
  VBModal,
  BFormSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ConfiguracionServices from "../modules/configuracion/general/services/index";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
    BModal,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        correo: "",
        password: "",
      },
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      modal: false,
      sucursalItems: [
        { text: "CASA MATRIZ - TARIJA CENTRAL", value: 1 },
        { text: "SUCURSAL 1 - SUCRE", value: 2 },
        { text: "SUCURSAL 2 - POTOSI", value: 3 },
      ],
      sucursal_id: null,
      appName: null,
      logo: null,
    };
  },
  beforeMount() {
    this.getInit();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async getInit() {
      const response = await ConfiguracionServices.init();
      this.logo = response.data.logo;
      this.appName = response.data.nombre;
      document.title = response.data.nombre;
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.submit();
        }
      });
    },

    ...mapActions({
      signIn: "auth/signIn",
    }),

    submit() {
      this.loading = true;
      this.signIn(this.form)
        .then(() => {
          this.$router.push({ name: "escritorio" }).catch(() => {});
          this.loading = false;
          // this.modal = true;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              // title: "Inicio de sesión Fallida",
              // text: "Estas credenciales no coinciden con nuestros registros!",
              title: e.response.data?.error
                ? e.response.data?.error
                : "Error de servidor",
              text: e.response.data.message,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
          this.loading = false;
        });
    },
    ingresar() {
      this.modal = false;
      this.$router.push({ name: "escritorio" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
